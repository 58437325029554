import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="বালের যোগাযোগ" description="বালের ব্লগের সাথে যোগাযোগ করুন" mdxType="SEO" />
    <h1 {...{
      "id": "যোগাযোগ"
    }}>{`যোগাযোগ`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "688px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "52.083333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/webp;base64,UklGRnAAAABXRUJQVlA4IGQAAADQAwCdASoUAAoAPtFWo0uoJKMhsAgBABoJZQC2yBk6S3LTnHa9LAAA/t2O/6GdkosfW4ud2gf6Wtez37OeTjDO5jC1HY1a9gnBDUMCaSR7X9Ecr++XwM4GkR0+EH2wnIHT4AAA')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/68a918eb48d5050b8d90fc9d7724e2c4/d3be9/question_unsplash.webp 480w", "/static/68a918eb48d5050b8d90fc9d7724e2c4/01c7f/question_unsplash.webp 688w"],
            "sizes": "(max-width: 688px) 100vw, 688px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/68a918eb48d5050b8d90fc9d7724e2c4/d3be9/question_unsplash.webp 480w", "/static/68a918eb48d5050b8d90fc9d7724e2c4/01c7f/question_unsplash.webp 688w"],
            "sizes": "(max-width: 688px) 100vw, 688px",
            "type": "image/webp"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/68a918eb48d5050b8d90fc9d7724e2c4/01c7f/question_unsplash.webp",
            "alt": "বালের যোগাযোগ ",
            "title": "source: unsplash/evan__bray",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span></p>
    <p>{`আশেপাশে খেয়াল করুন। যোগাযোগের বিভিন্ন মাধ্যম দেখতে পাবেন। একটায় যোগাযোগ করতে না পারলে আরেকটাই ট্রাই করুন।`}</p>
    <ul>
      <li parentName="ul">{`ই-মেইল: `}<strong parentName="li"><a parentName="strong" {...{
            "href": "mailto:ki@baal.xyz",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`ki@baal.xyz`}</a></strong></li>
      <li parentName="ul">{`ফেসবুক পেজ: `}<strong parentName="li"><a parentName="strong" {...{
            "href": "https://fb.com/baal.xyz",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`বালের ব্লগ`}</a></strong></li>
    </ul>
    <p><strong parentName="p">{`যেভাবেই হোক যোগাযোগ করুন বাল।`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      